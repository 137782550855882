import { graphql } from "gatsby"
import React, { useMemo } from "react"
import { ContentfulIndividualArtistPageQuery } from "../../../types/gatsby-graphql"
import { shortAddress } from "../../components/ConnectWalletMessage/ConnectWalletMessage"
import { NFTCard } from "../../components/NFTCard/NFTCard"
import ProfileSocialMedias from "../../components/ProfileSocialMedias/ProfileSocialMedias"
import { CapitalizeAll } from "../../utils/string"
import * as styles from "./Artist.module.scss"
import loadable from "@loadable/component"
import { enricheLotWithAuction, getAuctionsIds } from "../../utils/auction"

const Artists = (props: any) => {
  const tabs = ["Artworks Created", "Artworks Collected"]
  const search = props.location.search.split("=")
  const tab = search[1] ? tabs[1] : tabs[0]
  const data: ContentfulIndividualArtistPageQuery = props.data
  const artistName = [
    CapitalizeAll(data.allContentfulLot?.edges[0]?.node?.artist?.firstName!),
    CapitalizeAll(data.allContentfulLot?.edges[0]?.node?.artist?.middleName!),
    CapitalizeAll(data.allContentfulLot?.edges[0]?.node?.artist?.lastName!),
  ].join(" ")

  const LoadableGalleryMonitor = loadable(
    () => import("../../components/GalleryMonitor/GalleryMonitor")
  )

  const auctions = useMemo(() => {
    const lots = data?.allContentfulLot.edges.filter(
      item => item.node.auction !== null
    )
    return getAuctionsIds(lots)
  }, [data])

  console.log(data)
  return (
    <div className={styles.artistContainer}>
      {data.allContentfulLot?.edges[0]?.node?.artist?.coverImage?.file?.url ? (
        <img
          className={styles.artistBackground}
          src={
            data.allContentfulLot?.edges[0]?.node?.artist?.coverImage?.file
              ?.url!
          }
          alt={data.allContentfulLot?.edges[0]?.node?.artist?.contentful_id}
        />
      ) : (
        <div className={styles.artistBackground}></div>
      )}
      <div className={styles.artistHeader}>
        <img
          src={
            data.allContentfulLot?.edges[0]?.node?.artist?.profilePicture?.file
              ?.url!
          }
          alt={data.allContentfulLot?.edges[0]?.node?.artist?.username!}
        />
        <div className={styles.artistInner}>
          <div className={styles.artistDetail}>
            <h1>{`${artistName}`}</h1>
            <h3>@{data.allContentfulLot?.edges[0]?.node?.artist?.username}</h3>
            <span>
              {
                data.allContentfulLot?.edges[0]?.node?.artist?.artDescription
                  ?.artDescription
              }
            </span>
          </div>
          <div className={styles.artistAditionalInfo}>
            <span>
              <b>Wallet Address:</b>
            </span>
            <span>
              {shortAddress(
                data.allContentfulLot?.edges[0]?.node?.artist?.walletAddress!
              )}
            </span>
            <div className={styles.artistSocialMedia}>
              <ProfileSocialMedias
                facebook={
                  data.allContentfulLot?.edges[0]?.node?.artist?.facebookUrl!
                }
                twitter={
                  data.allContentfulLot?.edges[0]?.node?.artist?.twitterUrl!
                }
                instagram={
                  data.allContentfulLot?.edges[0]?.node?.artist?.instagramUrl!
                }
                globe={data.allContentfulLot?.edges[0]?.node?.artist?.website!}
              />
            </div>
            <span>
              {data.allContentfulLot?.edges[0]?.node?.artist?.website}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.artistDetailTable}>
        {/* <span>Represented by Howard Gallery (@howard)</span>
        <span>Senior Curator at Heiman Auctions (@heimanart)</span> */}
        <div className={styles.artistDetailTableWallet}>
          <span>Wallet Address:</span>
          <span>
            {shortAddress(
              data.allContentfulLot?.edges[0]?.node?.artist?.walletAddress!
            )}
          </span>
        </div>
        <div className={styles.artistDetailTableWebSite}>
          <span>Website:</span>
          <span>
            <b>{data.allContentfulLot?.edges[0]?.node?.artist?.website}</b>
          </span>
        </div>
        <div className={styles.artistDetailTableSocialMedia}>
          <ProfileSocialMedias
            facebook={
              data.allContentfulLot?.edges[0]?.node?.artist?.facebookUrl!
            }
            twitter={data.allContentfulLot?.edges[0]?.node?.artist?.twitterUrl!}
            instagram={
              data.allContentfulLot?.edges[0]?.node?.artist?.instagramUrl!
            }
            globe={data.allContentfulLot?.edges[0]?.node?.artist?.website!}
          />
        </div>
      </div>

      <div className={styles.artistFilters}>
        {/* <Tabs
          tabs={tabs}
          locationsProps={props}
          classname={styles.artistTabs}
        /> */}
      </div>

      <div className={styles.artistArtworks}>
        <h1>
          {tab} by {artistName}
        </h1>
      </div>
      <div className={styles.artistNFTList}>
        <LoadableGalleryMonitor
          auctions={auctions.filter(String)}
          render={({ result }: any) => {
            const enrichedData = enricheLotWithAuction(
              data?.allContentfulLot.edges || [],
              result?.auctions
            )

            return (
              <>
                {enrichedData.map((mapData: any) => {
                  const name = [
                    mapData?.lot?.artist?.firstName,
                    mapData?.lot?.artist?.middleName,
                    mapData?.lot?.artist?.lastName,
                  ]
                    .join(" ")
                    .trimEnd()
                  return (
                    <NFTCard
                      title={mapData?.lot?.auction?.mint?.name!}
                      assetType={
                        mapData?.lot?.auction?.mint?.mediaAsset?.media
                          ?.mimeType!
                      }
                      imgUrl={
                        mapData?.lot?.auction?.mint?.mediaAsset?.media?.file
                          ?.url!
                      }
                      price={mapData?.auction?.reservePriceInETH!}
                      by={name!}
                      linkTo={`/artwork/${mapData?.lot?.contentful_id}`}
                    />
                  )
                })}
              </>
            )
          }}
        />
      </div>
    </div>
  )
}

export default Artists

export const artistQuery = graphql`
  query ContentfulIndividualArtistPage($contentful_id: String!) {
    allContentfulLot(
      filter: { artist: { contentful_id: { eq: $contentful_id } } }
    ) {
      edges {
        node {
          lotId
          ended
          contentful_id
          artist {
            website
            walletAddress
            username
            twitterUrl
            spaceId
            referenceLinks {
              referenceLinks
            }
            middleName
            lastName
            instagramUrl
            firstName
            facebookUrl
            email
            contactNumber
            location
            contentful_id
            artDescription {
              artDescription
            }
            profilePicture {
              gatsbyImageData
              file {
                url
              }
            }
            coverImage {
              file {
                url
              }
            }
          }
          auction {
            id
            maximumIncrease
            reservePriceInEth
            quantity
            percentageIncrease
            status
            type
            name
            mint {
              tokenId
              name
              contentfulid
              contentful_id
              mediaAsset {
                media {
                  file {
                    url
                  }
                  title
                  mimeType
                  description
                }
                main
              }
              id
              artist
              auctionDetail: auction {
                quantity
                reservePriceInEth
                percentageIncrease
                maximumIncrease
              }
            }
            contentfulid
            contentful_id
          }
        }
      }
    }
  }
`
