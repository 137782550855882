// extracted by mini-css-extract-plugin
export var artistAditionalInfo = "Artist-module--artistAditionalInfo--bca4b";
export var artistArtworks = "Artist-module--artistArtworks--2b852";
export var artistBackground = "Artist-module--artistBackground--c0bc2";
export var artistContainer = "Artist-module--artistContainer--1ec73";
export var artistDetail = "Artist-module--artistDetail--be3c5";
export var artistDetailTable = "Artist-module--artistDetailTable--5c9fb";
export var artistDetailTableWallet = "Artist-module--artistDetailTableWallet--13a8e";
export var artistDetailTableWebSite = "Artist-module--artistDetailTableWebSite--9dc33";
export var artistFilters = "Artist-module--artistFilters--97547";
export var artistHeader = "Artist-module--artistHeader--a4643";
export var artistInner = "Artist-module--artistInner--0e9a7";
export var artistNFTList = "Artist-module--artistNFTList--5b0ce";
export var artistSocialMedia = "Artist-module--artistSocialMedia--d7556";
export var artistTabs = "Artist-module--artistTabs--5c8f9";